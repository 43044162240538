import { observer } from "mobx-react-lite";
import React, { FC, useRef, useEffect } from "react";

import Cluster, { MarkedState } from "@core/profiler/Cluster";
import DatasetStorage from "@core/workspace/dataset-storage/DatasetStorage";
import { Phrase } from "@core/profiler/types";

import Trigger from "../../../../../RunnerPanel/TriggersEdit/Trigger";
import { ReactComponent as ArrowIcon } from "../../../../assets/arrow-down.svg";

import Checkbox from "../Checkbox";
import SubRow from "../SubRow";
import * as S from "./styled";
import { debounce } from "lodash";

interface Props {
  style?: any;
  index: number;
  currentSessionPhrase: string | null;
  searchRequest: string;
  dataset: DatasetStorage | undefined;
  cluster: Cluster;
  setRowHeight: (index: number, size: number) => void;
  onExpand: (cluster: Cluster, expanded: boolean) => void;
  onShowDialog: (phrase: Phrase) => void;
}

const Row: FC<Props> = ({
  style = {},
  searchRequest,
  currentSessionPhrase,
  index,
  dataset,
  cluster,
  setRowHeight,
  onExpand,
  onShowDialog,
}) => {
  const rowEl = useRef<HTMLDivElement>(null);
  const phrases = cluster.phrases.filter((phrase) => phrase.isInFilter === undefined || phrase.isInFilter === true);

  useEffect(() => {
    if (rowEl.current === null) return;
    const observer = new ResizeObserver(debounce((e) => {
      if (e[0] && e[0].contentRect.height !== 0) {
        setRowHeight(index, e[0].contentRect.height);
      }
    }));

    observer.observe(rowEl.current);
    return () => observer.disconnect();
  }, [rowEl]);

  return (
    <S.Container style={style}>
      <div ref={rowEl}>
        <S.Label>
          <S.LabelBtn $expanded={cluster.isExpand} onClick={() => onExpand(cluster, !cluster.isExpand)}>
            <span>
              <ArrowIcon />
            </span>
            <S.Title>
              <span>{cluster.title}</span>
            </S.Title>
            <span>
              <S.Badge>{phrases.length}</S.Badge>
            </span>
          </S.LabelBtn>
          <S.Data>
            <span>
              <Checkbox
                isReadOnly={cluster.markedState === MarkedState.READ_ONLY}
                checked={cluster.markedState === MarkedState.ALL || cluster.markedState === MarkedState.READ_ONLY}
                isPartiallySelected={cluster.markedState === MarkedState.PARTICULARY}
                onChange={() => cluster.toggleMarkAll()}
              />
            </span>
            <span>
              {cluster.triggers.map((trigger) => (
                <Trigger key={trigger.id} phrase="" readOnly trigger={trigger} />
              ))}
            </span>
          </S.Data>
        </S.Label>

        {cluster.isExpand && (
          <S.SubTable>
            <SubRow
              currentSessionPhrase={currentSessionPhrase}
              searchRequest={searchRequest}
              dataset={dataset}
              cluster={cluster}
              onShowDialog={onShowDialog}
            />
          </S.SubTable>
        )}
      </div>
    </S.Container>
  );
};

export default observer(Row);
