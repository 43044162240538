import oidc from "oidc-client";
import { action, computed, makeObservable, observable, runInAction } from "mobx";

import storage from "../misc/LocalStorage";
import analytics from "../misc/analytics";
import { Emitter } from "../misc/emitter";

import { IAccount } from "./interface";
import Account from "./Account";
import { selectServers } from "@core/Selector";

export class AccountsManager {
  server: string;
  authServer: string;
  account: IAccount | undefined;
  insecure: boolean;
  isInitalized: boolean;

  private readonly _onDidAccount = new Emitter<IAccount>();
  public readonly onDidAccount = this._onDidAccount.event;

  constructor(account: IAccount | undefined) {
    const server = selectServers();
    this.server = server.app;
    this.insecure = server.auth.startsWith("http:");
    this.authServer = server.auth.startsWith("http") ? server.auth : `https://${server.auth}`;
    this.account = account;
    this.isInitalized = false;

    makeObservable(this, {
      account: observable,
      isInitalized: observable,
      isAuth: computed,
      loadCurrentAccount: action,
      authAccount: action,
      logout: action,
    });
    if (account === undefined) {
      new oidc.UserManager({})
      .signinRedirectCallback(window.location.href)
      .then((user) => {
        window.history.pushState(null, "", window.location.pathname);
        const name = user.profile.email ?? "user";
        const token = user.access_token;
        this.authAccount(name, token);
      })
      .catch(() => this.loadCurrentAccount())
      .finally(() => runInAction(() => { this.isInitalized = true; }))
    }
  }

  authAccount(name: string, token: string) {
    storage.set("currentAccount", name);
    analytics.identity(name);

    this.account = new Account(name, token, this.server, this.insecure);
    this._onDidAccount.fire(this.account);
  }

  public loadCurrentAccount() {
    const name = storage.get<string>("currentAccount");
    if (name == null) return this.logout();

    const account = Account.fromName(name, this.server);
    if (account == null) return this.logout();

    analytics.identity(name);
    this.account = account;
    this._onDidAccount.fire(account);
  }

  get isAuth() {
    return this.account !== undefined
  }

  logout() {
    storage.set("currentAccount", null);
    analytics.track("logout");
    analytics.logout();

    this.account = undefined;
    this._onDidAccount.fire(undefined);

    const manager = new oidc.UserManager({
      authority: this.authServer,
      client_id: "platform_client",
      redirect_uri: window.location.href,
      popup_redirect_uri: window.location.href,
      response_type: "id_token token",
      scope: "openid email platform_api user_properties",
      prompt: "logout",
    });
    void manager.signoutRedirect();
  }

  login(prompt: string = "login") {
    const manager = new oidc.UserManager({
      authority: this.authServer,
      client_id: "platform_client",
      redirect_uri: window.location.href,
      popup_redirect_uri: window.location.href,
      response_type: "id_token token",
      scope: "openid email platform_api user_properties",
      prompt: prompt,
    });

    analytics.track(prompt);
    void manager.signinRedirect();
  }
}

