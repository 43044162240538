import React, { FC, useState } from 'react';
import { Input, Dropdown } from 'semantic-ui-react';
import { CartesiaVoiceSettings, CARTESIA_EMOTIONS, VOICE_MODELS, VOICE_PROVIDERS } from '../../../../core/api/VoiceConfig';
import { SettingsContainer, SettingRow, Label, SliderContainer, ValueDisplay } from './styles';

export interface Props {
  settings: CartesiaVoiceSettings;
  onChange: (settings: CartesiaVoiceSettings) => void;
}

export const CartesiaSettings: FC<Props> = ({ settings, onChange }) => {
  const [customModelId, setCustomModelId] = useState('');

  const handleChange = (key: keyof CartesiaVoiceSettings, value: any) => {
    onChange({
      ...settings,
      [key]: value
    });
  };

  const handleOptionChange = (key: keyof CartesiaVoiceSettings["options"], value: any) => {
    onChange({
      ...settings,
      options: {
        ...settings.options,
        [key]: value
      }
    });
  };

  const handleModelChange = (value: string) => {
    if (value === 'custom') {
      handleChange('model', customModelId || '');
    } else {
      handleChange('model', value);
      setCustomModelId('');
    }
  };

  const handleCustomModelChange = (value: string) => {
    setCustomModelId(value);
    handleChange('model', value);
  };

  const handleEmotionsChange = (_: any, { value }: { value: string[] }) => {
    const lastSelected = value[value.length - 1];
    if (!lastSelected) {
      handleOptionChange('emotions', value);
      return;
    }

    // Get the emotion type of the last selected item (e.g., 'anger' from 'anger:low')
    const lastSelectedType = lastSelected.split(':')[0];

    // Filter out any existing emotions of the same type using the nested emotions
    const filteredEmotions = settings.options.emotions.filter(emotion => {
      const emotionType = emotion.split(':')[0];
      return emotionType !== lastSelectedType;
    });

    // Add the newly selected emotion
    handleOptionChange('emotions', [...filteredEmotions, lastSelected]);
  };

  const modelOptions = VOICE_MODELS[VOICE_PROVIDERS.CARTESIA].map(model => ({
    key: model.id,
    value: model.id,
    text: model.name
  }));

  const selectedModel = VOICE_MODELS[VOICE_PROVIDERS.CARTESIA].find(m => m.id === settings.model)
    ? settings.model
    : 'custom';

  const emotionOptions = CARTESIA_EMOTIONS.map(emotion => ({
    key: emotion,
    value: emotion,
    text: emotion.split(':').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' - ')
  }));

  return (
    <SettingsContainer>
      <SettingRow>
        <Label>Model</Label>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div style={{ flex: 1 }}>
            <Dropdown
              selection
              fluid
              options={modelOptions}
              value={selectedModel}
              onChange={(_, { value }) => handleModelChange(value as string)}
            />
          </div>
          {selectedModel === 'custom' && (
            <div style={{ flex: 2 }}>
              <Input 
                fluid
                placeholder="Enter custom model ID"
                value={customModelId || settings.model}
                onChange={(e) => handleCustomModelChange(e.target.value)}
              />
            </div>
          )}
        </div>
      </SettingRow>

      <SettingRow>
        <SliderContainer>
          <Label>Speed</Label>
          <Input
            type="range"
            min={-1}
            max={1}
            step={0.1}
            value={settings.speed}
            onChange={(e) => handleChange('speed', parseFloat(e.target.value))}
          />
          <ValueDisplay>{settings.speed.toFixed(1)}</ValueDisplay>
        </SliderContainer>
      </SettingRow>

      <SettingRow>
        <Label>Emotions</Label>
        <Dropdown
          multiple
          selection
          fluid
          options={emotionOptions}
          value={settings.options.emotions}
          onChange={handleEmotionsChange}
          placeholder="Select emotions..."
        />
      </SettingRow>
    </SettingsContainer>
  );
}; 