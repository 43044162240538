import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";
import CopyButton from "../uikit/CopyButton";
import { IAccount } from "@core/account/interface";
import * as dasha from "@dasha.ai/sdk/web";
import { Dropdown, Input, TextArea, Message } from "semantic-ui-react";
import "./styles.css";
import { Loader } from "semantic-ui-react";
import { ActionButton } from "../uikit";
import WaveSurfer from "wavesurfer.js";
import { useStore } from "../core/api/GlobalStoreContext";
import { VoiceSelector } from "../widgets/VoiceSettings/VoiceSelector";
import { DEFAULT_PROVIDER_SETTINGS, ProviderVoiceSettings, transformProviderVoiceSettingsToDTO, VOICE_PROVIDERS } from "../core/api/VoiceConfig";
interface HistoryItem {
  text: string;
  speed: number;
  speaker: string;
  language: string;
  blob: Blob;
}

interface HistoryItemCombo {
  text: string;
  key: string;
  value: HistoryItem;
}

export const Tts: FC<{}> = ({}) => {
  const { account } = useStore();

  const [text, setText] = useState("Hi! How are you doing?");
  const [loading, setLoading] = useState(false);
  const containerRef = useRef();
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [voice, setVoice] = useState(DEFAULT_PROVIDER_SETTINGS[VOICE_PROVIDERS.ELEVEN_LABS]);

  useEffect(() => {
    if (!containerRef.current) return;
    const ws = WaveSurfer.create({
      container: containerRef.current,
      plugins: [],
      height: 200,
      autoplay: true,
    });
    setWavesurfer(ws);

    return () => {
      ws.destroy();
    };
  }, [containerRef]);

  const onPlayClick = useCallback(() => {
    setLoading(true);
    setError(undefined);
    if (wavesurfer === undefined) {
      return;
    }
    const fetchData = async () => {
      const a = account.connect();
      const options = { providerName: "dasha", account: a };
      const voiceDTO = transformProviderVoiceSettingsToDTO(voice);
      const buffer = await dasha.tts.synthesize(text, voiceDTO, options);
      const blob = new Blob([buffer], { type: "audio/mp3" });
      wavesurfer?.loadBlob(blob);
    };

    fetchData()
      .catch((e) => setError(e.message))
      .then(() => setLoading(false));
  }, [wavesurfer, voice, account, text]);
  const getPhrasemap = useCallback(() => {
    return JSON.stringify(transformProviderVoiceSettingsToDTO(voice), null, 4);
  }, [voice]);
  return (
    <div className="defaultRoot" id="grid-workspace">
      <div>
        <h2>Text To Speech</h2>
        <VoiceSelector voice={voice} handleChange={(newVoice) => setVoice(newVoice)} />
        {error && <Message negative>Error {error}</Message>}
        <Loader active={loading}>Loading..</Loader>
        <CopyButton tooltipText="Copy phrasemap settings" hasIcon={true} clipboard={getPhrasemap()}>Copy settings</CopyButton>
        <h2>Text</h2>
        <TextArea
          value={text}
          placeholder="Your text"
          className="dasha-textarea"
          style={{ minHeight: 100, minWidth: 400 }}
          onChange={(e, { value }) => {
            setText(value?.toString() ?? "");
          }}
        />
      </div>
      
      <ActionButton onClick={onPlayClick}>[{wavesurfer?.isPlaying() ? "Pause" : "Play"}]</ActionButton>

      <div ref={containerRef} className="waveform" />
    </div>
  );
};
