import type { BundleConfigOptions } from "./configs/options";
import type * as ESBUILD from "esbuild-wasm";

export type { ESBUILD };

import { EXTERNAL } from "./plugins/external";
import { HTTP } from "./plugins/http";
import { CDN } from "./plugins/cdn";
import { ALIAS } from "./plugins/alias";
import { VIRTUAL_FS } from "./plugins/virtual-fs";

import { DefaultConfig } from "./configs/options";
import { EVENTS } from "./configs/events";
import { STATE } from "./configs/state";
import { deepAssign } from "./utils/deep-equal";
import { createNotice } from "./utils/create-notice";

export const INPUT_EVENTS = {
  build: build,
  init: init,
};

export async function init() {
  try {
    if (!STATE.initialized) {
      STATE.initialized = true;
      EVENTS.emit("init.start");

      // Try multiple CDNs in case one fails
      const cdns = [
        "https://unpkg.com/esbuild-wasm@0.15.18/esbuild.wasm",
        "https://cdn.jsdelivr.net/npm/esbuild-wasm@0.15.18/esbuild.wasm",
        "https://esm.sh/esbuild-wasm@0.15.18/esbuild.wasm"
      ];

      STATE.esbuild = await import("esbuild-wasm");
      
      let initialized = false;
      let lastError = null;

      for (const wasmURL of cdns) {
        try {
          await STATE.esbuild.initialize({
            worker: true,
            wasmURL
          });
          initialized = true;
          console.log(`Successfully initialized esbuild from ${wasmURL}`);
          break;
        } catch (error) {
          console.warn(`Failed to initialize esbuild from ${wasmURL}:`, error);
          lastError = error;
        }
      }

      if (!initialized) {
        throw lastError || new Error("Failed to initialize esbuild from all CDNs");
      }

      EVENTS.emit("init.complete");
    }

    return STATE.esbuild;
  } catch (error) {
    EVENTS.emit("init.error", error);
    console.error("Failed to initialize esbuild:", error);
    throw error;
  }
}

export async function build(opts: BundleConfigOptions = {}): Promise<any> {
  if (!STATE.initialized) EVENTS.emit("init.loading");

  const CONFIG = deepAssign({}, DefaultConfig, opts) as BundleConfigOptions;

  const { build: bundle } = await init();
  const { define = {}, plugins = [], ...esbuildOpts } = CONFIG.esbuild ?? {};

  // Stores content from all external outputed files, this is for checking the gzip size when dealing with CSS and other external files
  let outputs: ESBUILD.OutputFile[] = [];
  let contents: ESBUILD.OutputFile[] = [];
  let result: ESBUILD.BuildResult | ESBUILD.BuildIncremental;

  try {
    try {
      result = await bundle({
        entryPoints: CONFIG?.entryPoints ?? [],
        loader: {
          ".png": "file",
          ".jpeg": "file",
          ".ttf": "file",
          ".svg": "text",
          ".html": "text",
          ".scss": "css",
        },
        define,
        write: false,
        outdir: "/",
        plugins: [
          ...plugins,
          ALIAS(EVENTS, STATE, CONFIG),
          EXTERNAL(EVENTS, STATE, CONFIG),
          HTTP(EVENTS, STATE, CONFIG),
          CDN(EVENTS, STATE, CONFIG),
          VIRTUAL_FS(EVENTS, STATE, CONFIG),
        ],
        ...esbuildOpts,
      });
    } catch (e) {
      if (e.errors) {
        // Log errors with added color info. to the virtual console
        const asciMsgs = [...(await createNotice(e.errors, "error", false))];
        const htmlMsgs = [...(await createNotice(e.errors, "error"))];

        EVENTS.emit("logger.error", asciMsgs, htmlMsgs);

        const message = htmlMsgs.length > 1 ? `${htmlMsgs.length} error(s) ` : "";
        return EVENTS.emit("logger.error", message);
      } else throw e;
    }

    // Create an array of assets and actual output files, this will later be used to calculate total file size
    outputs = await Promise.all([...STATE.assets].concat(result?.outputFiles as ESBUILD.OutputFile[]));

    contents = await Promise.all(
      outputs?.flatMap(({ path, text, contents }): ESBUILD.OutputFile[] => {
        if (/\.map$/.test(path)) return [];

        // For debugging reasons, if the user chooses verbose, print all the content to the Shared Worker console
        if (esbuildOpts?.logLevel == "verbose") {
          const ignoreFile = /\.(wasm|png|jpeg|webp)$/.test(path);
          if (ignoreFile) {
            EVENTS.emit("logger.log", "Output File: " + path);
          } else {
            EVENTS.emit("logger.log", "Output File: " + path + "\n" + text);
          }
        }

        return [{ path, text, contents }];
      })
    );

    // Ensure a fresh filesystem on every run
    // FileSystem.clear();

    // Reset assets
    // STATE.assets = [];

    return {
      contents,
      outputs,
      ...result,
    };
  } catch (e) {
    // nope
  }
}
