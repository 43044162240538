import styled, { css } from 'styled-components';
import { Modal, Button, Dropdown, Search } from 'semantic-ui-react';
import { Label, Input, TextArea } from '../components/FormElements';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 0 24px 0;
`;

const dropdownStyles = (isSearchable: boolean) => css`
  background: ${({ theme }) => theme.colors.surfaceHover};
  border: 1px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.textPrimary};
  min-height: 38px !important;
  padding: 8px 12px !important;
  padding-right: 32px !important;
  
  > input.search {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    padding: inherit !important;
    padding-right: 32px !important;
    color: ${({ theme }) => theme.colors.textPrimary} !important;
    line-height: 22px !important;
    height: 100% !important;
    width: 100% !important;
    border: none !important;
    outline: none !important;
    background: ${({ theme }) => theme.colors.surfaceHover} !important;
    margin: 0 !important;
    z-index: 1 !important;

    &::selection {
      background: ${({ theme }) => theme.colors.accent}44;
    }
  }

  > .dropdown.icon {
    position: absolute !important;
    right: 12px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    margin: 0 !important;
    z-index: 21 !important;
  }

  &.active.search {
    > input.search {
      z-index: 20 !important;
      opacity: 1 !important;
    }
    
    > .text {
      opacity: 0 !important;
      pointer-events: none !important;
    }
  }

  &:not(.active), &.active:not(.search) {
    > input.search {
      opacity: 0 !important;
      pointer-events: none !important;
    }
    
    > .text {
      opacity: 1 !important;
      z-index: 20 !important;
    }
  }

  > .text {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    padding: inherit !important;
    padding-right: 32px !important;
    line-height: inherit !important;
    height: 100% !important;
    width: 100% !important;
  }

  .menu {
    background: ${({ theme }) => theme.colors.surface};
    border: 1px solid ${({ theme }) => theme.colors.border};
    margin-top: 2px !important;
    
    .item {
      border: none;
      color: ${({ theme }) => theme.colors.textPrimary};
      padding: 6px 12px;
      min-height: 32px;
      
      &:hover {
        background: ${({ theme }) => theme.colors.surfaceHover};
      }

      &.selected, &.active {
        background: ${({ theme }) => theme.colors.accent};
        color: white;
      }

      .icon {
        margin-right: 8px !important;
        float: none !important;
        opacity: 1 !important;
      }

      > div {
        display: flex;
        align-items: center;
      }
    }
  }

  .icon {
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  &:hover, &.active {
    border-color: ${({ theme }) => theme.colors.accent};
    
    .icon {
      color: ${({ theme }) => theme.colors.textPrimary};
    }

    .menu {
      border-color: ${({ theme }) => theme.colors.accent} !important;
      border-top-color: ${({ theme }) => theme.colors.border} !important;
    }
  }

  .default.text {
    color: ${({ theme }) => theme.colors.textSecondary};
    opacity: 0.8;
  }

  &.active {
    background: ${({ theme }) => theme.colors.surfaceHover} !important;
  }
`;

export const StyledVoiceDropdown = styled(Dropdown)`
  &.ui.search.selection.dropdown {
    ${dropdownStyles(true)}
  }
`;

export const StyledDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    ${dropdownStyles(false)}
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  ${Label} {
    margin-bottom: 4px;
  }

  ${Input}, ${TextArea} {
    background: ${({ theme }) => theme.colors.surfaceHover};
    border: 1px solid ${({ theme }) => theme.colors.border};
    color: ${({ theme }) => theme.colors.textPrimary};
    padding: 12px;
    border-radius: 4px;

    &::placeholder {
      color: ${({ theme }) => theme.colors.textSecondary};
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.accent};
      outline: none;
    }
  }

  ${TextArea} {
    min-height: 100px;
    resize: vertical;
  }
`;

export const StyledModal = styled(Modal)`
  &.ui.modal {
    background: ${({ theme }) => theme.colors.surface};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 8px;
    
    > .header {
      background: ${({ theme }) => theme.colors.surfaceHover};
      color: ${({ theme }) => theme.colors.textPrimary};
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
      padding: 20px 24px;
      font-size: 20px;
      font-weight: 600;
    }
    
    > .content {
      background: ${({ theme }) => theme.colors.surface};
      color: ${({ theme }) => theme.colors.textPrimary};
      padding: 24px;

      ${FormSection}:first-child {
        margin-top: 0;
      }
    }
    
    > .actions {
      background: ${({ theme }) => theme.colors.surfaceHover};
      border-top: 1px solid ${({ theme }) => theme.colors.border};
      padding: 16px 24px;
      
      .ui.button {
        padding: 10px 20px;
        font-weight: 500;
        
        &.secondary {
          background: transparent;
          color: ${({ theme }) => theme.colors.textPrimary};
          border: 1px solid ${({ theme }) => theme.colors.border};
          
          &:hover {
            background: ${({ theme }) => theme.colors.surfaceHover};
          }
        }
        
        &.primary {
          background: ${({ theme }) => theme.colors.accent};
          color: #ffffff;
          
          &:hover {
            background: ${({ theme }) => theme.colors.accentHover};
          }
        }
      }
    }
  }
`;

export const SmallText = styled.small`
  color: ${({ theme }) => theme.colors.textSecondary};
  display: block;
  margin-top: 4px;
`;

export const ManageVoicesButton = styled(Button)`
  &.ui.button {
    padding: 8px !important;
    width: 38px;
    height: 38px;
    margin: 0 !important;
    background: ${({ theme }) => theme.colors.surfaceHover} !important;
    color: ${({ theme }) => theme.colors.textSecondary} !important;
    border: 1px solid ${({ theme }) => theme.colors.border} !important;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
      color: ${({ theme }) => theme.colors.textPrimary} !important;
      border-color: ${({ theme }) => theme.colors.accent} !important;
      background: ${({ theme }) => theme.colors.surfaceHover} !important;
    }

    &:active {
      background: ${({ theme }) => theme.colors.surfaceHover} !important;
      border-color: ${({ theme }) => theme.colors.accent} !important;
    }

    i.icon {
      margin: 0 !important;
      font-size: 16px;
    }
  }
`;