import { IAccount } from "@core/account/interface";
import { makeAutoObservable, runInAction } from "mobx";
import * as dasha from "@dasha.ai/sdk/web";
import { CloneRequest } from "@dasha.ai/sdk/web/rest-api/tts";
import { VOICE_PROVIDERS } from "./VoiceConfig";

const formatLanguageCode = (language: string | null | undefined): string => {
    if (!language) return '';
    const [lang, region] = language.split('-');
    return region ? `${lang.toLowerCase()}-${region.toUpperCase()}` : lang.toLowerCase();
};

const detectGender = (text: string | null | undefined): string => {
    if (!text) return '';
    const lowerText = text.toLowerCase();
    if (lowerText.match(/\b(female|woman|women)\b/)) return 'female';
    if (lowerText.match(/\b(male|man|men)\b/)) return 'male';
    return '';
};

const formatVoice = (voice: dasha.coreapi.TtsCloneResponseDto): dasha.coreapi.TtsCloneResponseDto => {
    // This is a workaround since the API may not return gender information at all
    const gender = voice.labels?.gender || 
                  detectGender(voice.description) || 
                  detectGender(voice.name) || 
                  '';
    return {
        ...voice,
        language: formatLanguageCode(voice.language),
        labels: {
            ...voice.labels,
            gender
        }
    };
};

export class VoiceStore {
    private readonly STORAGE_KEY = "voices";
    private readonly account: IAccount;
    public loading: boolean = false;
    public voices: dasha.coreapi.TtsCloneResponseDto[] = [];
    
    constructor(account: IAccount) {
        this.account = account;
        
        makeAutoObservable(this);
    }

    public TryReload() {
        if (this.voices.length === 0 && !this.loading) {
            this.Reload();
        }
    }

    private Reload() {
        runInAction(() => {
            this.loading = true;
        });

        // Initialize with stored data
        try {
            const savedVoices = this.account.getValue<dasha.coreapi.TtsCloneResponseDto[]>(this.STORAGE_KEY);
            if (savedVoices) {
                runInAction(() => {
                    this.voices = savedVoices.map(formatVoice);
                });
            }
        } catch (error) {
            console.error('Failed to load saved voices:', error);
        }
        
        try {
            dasha.tts.listVoicesAsync(dasha.coreapi.ListOptions.ShowAll, { account: this.account.connect()}).then((voices) => {
                runInAction(() => {
                    this.voices = voices.map(formatVoice);
                });
            }).catch(error => {
                console.error('Failed to load voices during initialization:', error);
            }).finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
        } catch (error) {
            console.error('Failed to initialize voices:', error);
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    public async loadVoices() {
        try {
            this.loading = true;
            const voices = await dasha.tts.listVoicesAsync(dasha.coreapi.ListOptions.ShowAll, { account: this.account.connect()});
            
            runInAction(() => {
                this.voices = voices.map(formatVoice);
                this.saveVoices();
            });
        } catch (error) {
            console.error('Failed to load voices:', error);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    private async saveVoices() {
        this.account.setValue(this.STORAGE_KEY, this.voices);
    }

    public async createVoice(request: CloneRequest, audios: (File | Blob)[]): Promise<dasha.coreapi.TtsCloneResponseDto> {
        try {
            this.loading = true;
            const voice = await dasha.tts.cloneAsync(request, audios, {account: this.account.connect()});
            const formattedVoice = formatVoice(voice);
            
            runInAction(() => {
                this.voices = [formattedVoice, ...this.voices];
                this.saveVoices();
            });

            return formattedVoice;
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    public async getVoice(voiceId: string): Promise<dasha.coreapi.TtsCloneResponseDto | undefined> {
        const voice = this.voices.find(v => v.voiceId === voiceId);
        if (!voice) 
            return undefined;
        return formatVoice(voice);
    }

    public getVoicesForProvider(providerId: string): dasha.coreapi.TtsCloneResponseDto[] {
        return this.voices.filter(voice => voice.provider.toString() === providerId).map(formatVoice);
    }

    public getAvailableProviders(): string[] {
        return Object.values(VOICE_PROVIDERS);
    }

    public async deleteVoice(voiceId: string): Promise<void> {
        try {
            this.loading = true;
            const voice = this.voices.find(v => v.voiceId === voiceId);
            if (!voice) {
                throw new Error('Voice not found');
            }
            await dasha.tts.deleteCloneAsync(voice.id, {account: this.account.connect()});
            
            runInAction(() => {
                this.voices = this.voices.filter(v => v.voiceId !== voiceId);
                this.saveVoices();
            });
        } catch (error) {
            console.error('Failed to delete voice:', error);
            throw error;
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
} 