import * as dasha from "@dasha.ai/sdk/web";

// Add consolidated voice types at the top of the file
export type VoiceGender = 'male' | 'female';
export type VoiceLanguage = string; // e.g. 'en-US', 'ru-RU'

export interface VoiceCommon {
  providerVoiceId: string; //Provider's voice ID
  language: string;
  provider: string;
  gender?: VoiceGender;
  name?: string;
  description?: string;
  labels?: Record<string, string>;
}

// Update BaseVoiceSettings to extend VoiceCommon and remove duplicate properties
export interface BaseVoiceSettings extends VoiceCommon {
  model: string;
  speed: number;
  options: {} //Provider-specific options
}

export interface Voice extends VoiceCommon {
    id: string; //Dasha API voice ID
}

// Initial settings type that only requires language
export type InitialVoiceSettings = Pick<BaseVoiceSettings, 'language'>;

// Provider-specific voice settings
export interface ElevenLabsVoiceSettings extends BaseVoiceSettings {
  provider: typeof VOICE_PROVIDERS.ELEVEN_LABS;
  options: {
    similarityBoost: number;
    stability: number;
    style: number;
    useSpeakerBoost: boolean;
    optimizeStreamingLatency: number;
  };
}

export interface CartesiaVoiceSettings extends BaseVoiceSettings {
  provider: typeof VOICE_PROVIDERS.CARTESIA;
  speed: number;  // -1.0 to 1.0
  options: {
    emotions: string[];  // List of emotion settings
  };
}

export interface DashaVoiceSettings extends BaseVoiceSettings {
  provider: typeof VOICE_PROVIDERS.DASHA,
  speed: number;  //0.5 to 2
}

export const CARTESIA_EMOTIONS = [
  'anger:lowest',
  'anger:low',
  'anger:high',
  'anger:highest',
  'positivity:lowest',
  'positivity:low',
  'positivity:high',
  'positivity:highest',
  'surprise:lowest',
  'surprise:low',
  'surprise:high',
  'surprise:highest',
  'sadness:lowest',
  'sadness:low',
  'sadness:high',
  'sadness:highest',
  'curiosity:lowest',
  'curiosity:low',
  'curiosity:high',
  'curiosity:highest'
] as const;

export type ProviderVoiceSettings = ElevenLabsVoiceSettings | CartesiaVoiceSettings | DashaVoiceSettings;

export const VOICE_PROVIDERS = {
  ELEVEN_LABS: 'ElevenLabs',
  CARTESIA: 'Cartesia',
  DASHA: 'Dasha'
} as const;

export const VOICE_MODELS = {
  [VOICE_PROVIDERS.ELEVEN_LABS]: [
    { id: 'eleven_multilingual_v2', name: 'Multilingual V2' },
    { id: 'eleven_turbo_v2_5', name: 'Turbo V2.5' },
    { id: 'eleven_flash_v2_5', name: 'Flash V2.5' },
    { id: 'custom', name: 'Custom', isCustom: true }
  ],
  [VOICE_PROVIDERS.CARTESIA]: [
    { id: 'sonic', name: 'Sonic' },
    { id: 'custom', name: 'Custom', isCustom: true }
  ],
  [VOICE_PROVIDERS.DASHA]: [
    { id: 'common', name: 'Common'}
  ]
} as const;

// Default settings for each provider
export const DEFAULT_PROVIDER_SETTINGS: Record<string, ProviderVoiceSettings> = {
  [VOICE_PROVIDERS.ELEVEN_LABS]: {
    providerVoiceId: "fA2TI1m22NPQVhxhw9DE", // default voice id for ElevenLabs
    language: 'en-US',
    provider: VOICE_PROVIDERS.ELEVEN_LABS,
    model: 'eleven_flash_v2_5',
    speed: 1.0,
    options: {
      similarityBoost: 0.75,
      stability: 0.5,
      style: 0.3,
      useSpeakerBoost: true,
      optimizeStreamingLatency: 4
    }
  },
  [VOICE_PROVIDERS.CARTESIA]: {
    providerVoiceId: "3b78efc1-b4d1-4efb-8964-5d22ce0c43aa", // default voice id for Cartesia
    language: 'en-US',
    provider: VOICE_PROVIDERS.CARTESIA,
    model: 'sonic',
    speed: 0,
    options: {
      emotions: []
    }
  },
  [VOICE_PROVIDERS.DASHA]: {
    providerVoiceId: "jack",
    language: 'en-US',
    provider: VOICE_PROVIDERS.DASHA,
    model: 'common',
    speed: 1
  }
}; 

export const AVAILABLE_LANGUAGES: LanguageOption[] = [
  // English variants
  { value: 'en-US', label: 'English (USA)', flag: 'us' },
  { value: 'en-GB', label: 'English (UK)', flag: 'gb' },
  { value: 'en-AU', label: 'English (Australia)', flag: 'au' },
  { value: 'en-CA', label: 'English (Canada)', flag: 'ca' },

  // European languages
  { value: 'de-DE', label: 'German', flag: 'de' },
  { value: 'fr-FR', label: 'French (France)', flag: 'fr' },
  { value: 'fr-CA', label: 'French (Canada)', flag: 'ca' },
  { value: 'pt-BR', label: 'Portuguese (Brazil)', flag: 'br' },
  { value: 'pt-PT', label: 'Portuguese (Portugal)', flag: 'pt' },
  { value: 'it-IT', label: 'Italian', flag: 'it' },
  { value: 'es-ES', label: 'Spanish (Spain)', flag: 'es' },
  { value: 'es-MX', label: 'Spanish (Mexico)', flag: 'mx' },
  { value: 'nl-NL', label: 'Dutch', flag: 'nl' },
  { value: 'tr-TR', label: 'Turkish', flag: 'tr' },
  { value: 'pl-PL', label: 'Polish', flag: 'pl' },
  { value: 'sv-SE', label: 'Swedish', flag: 'se' },
  { value: 'bg-BG', label: 'Bulgarian', flag: 'bg' },
  { value: 'ro-RO', label: 'Romanian', flag: 'ro' },
  { value: 'cs-CZ', label: 'Czech', flag: 'cz' },
  { value: 'el-GR', label: 'Greek', flag: 'gr' },
  { value: 'fi-FI', label: 'Finnish', flag: 'fi' },
  { value: 'hr-HR', label: 'Croatian', flag: 'hr' },
  { value: 'sk-SK', label: 'Slovak', flag: 'sk' },
  { value: 'da-DK', label: 'Danish', flag: 'dk' },
  { value: 'uk-UA', label: 'Ukrainian', flag: 'ua' },
  { value: 'ru-RU', label: 'Russian', flag: 'ru' },
  { value: 'hu-HU', label: 'Hungarian', flag: 'hu' },
  { value: 'no-NO', label: 'Norwegian', flag: 'no' },
  
  // Asian and other languages
  { value: 'ja-JP', label: 'Japanese', flag: 'jp' },
  { value: 'zh-CN', label: 'Chinese', flag: 'cn' },
  { value: 'ko-KR', label: 'Korean', flag: 'kr' },
  { value: 'hi-IN', label: 'Hindi', flag: 'in' },
  { value: 'id-ID', label: 'Indonesian', flag: 'id' },
  { value: 'fil-PH', label: 'Filipino', flag: 'ph' },
  { value: 'ms-MY', label: 'Malay', flag: 'my' },
  { value: 'ta-IN', label: 'Tamil', flag: 'in' },
  { value: 'vi-VN', label: 'Vietnamese', flag: 'vn' },
  
  // Arabic variants
  { value: 'ar-SA', label: 'Arabic (Saudi Arabia)', flag: 'sa' },
  { value: 'ar-AE', label: 'Arabic (UAE)', flag: 'ae' }
];

// Helper function to get language name from code
export function getLanguageName(langCode: string): string {
  const language = AVAILABLE_LANGUAGES.find(lang => lang.value === langCode);
  return language?.label || langCode;
}

// Add the LanguageOption interface before its usage
// Export language mapping for reuse
export interface LanguageOption {
  value: string;
  label: string;
  flag: string;
}

// Add helper function to handle voice changes
export function createVoiceSettings(
  voice: dasha.coreapi.TtsCloneResponseDto,
  currentSettings: ProviderVoiceSettings | undefined
): ProviderVoiceSettings {
  const provider = voice.provider;
  console.log('Voice change - Provider:', provider);
  
  const baseSettings = {
    provider,
    providerVoiceId: voice.voiceId,
    language: (currentSettings?.language) || voice.language || '',
    model: DEFAULT_PROVIDER_SETTINGS[provider].model,
  };
  console.log('Voice change - Base settings:', baseSettings);

  const defaultSettings = provider === VOICE_PROVIDERS.CARTESIA
    ? {
        ...baseSettings,
        speed: (DEFAULT_PROVIDER_SETTINGS[VOICE_PROVIDERS.CARTESIA] as CartesiaVoiceSettings).speed,
        options: DEFAULT_PROVIDER_SETTINGS[VOICE_PROVIDERS.CARTESIA].options
      } as CartesiaVoiceSettings
    : {
        ...baseSettings,
        options: DEFAULT_PROVIDER_SETTINGS[VOICE_PROVIDERS.ELEVEN_LABS].options
      } as ElevenLabsVoiceSettings;
  
  console.log('Voice change - Final settings:', defaultSettings);

  return {
    ...(currentSettings as ProviderVoiceSettings),
    ...defaultSettings
  };
}

// Add type guards and utility functions
export const isElevenLabsSettings = (settings: ProviderVoiceSettings): settings is ElevenLabsVoiceSettings => {
  return settings.provider === VOICE_PROVIDERS.ELEVEN_LABS && 
         'options' in settings && 
         'similarityBoost' in settings.options;
};

export const isCartesiaSettings = (settings: ProviderVoiceSettings): settings is CartesiaVoiceSettings => {
  return settings.provider === VOICE_PROVIDERS.CARTESIA && 
         'options' in settings && 
         'emotions' in settings.options;
};

export const findVoiceById = (voices: dasha.coreapi.TtsCloneResponseDto[], voiceId: string): dasha.coreapi.TtsCloneResponseDto | undefined => {
  return voices.find(v => v.voiceId === voiceId);
};

export const getSearchableVoiceContent = (voice: dasha.coreapi.TtsCloneResponseDto): string => {
  return [
    voice.name,
    voice.description,
    voice.provider.toString(),
    voice.voiceId,
    voice.language,
    voice.labels?.gender,
    ...Object.values(voice.labels || {})
  ].filter(Boolean).join(' ');
};
