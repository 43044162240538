import styled from 'styled-components';
import { Modal, Button } from 'semantic-ui-react';

export const LoadingOverlay = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.surface}99;
  display: ${({ visible }) => visible ? 'block' : 'none'};
  z-index: 1;
`;

export const StyledConfirmModal = styled(Modal)`
  &.ui.modal {
    position: relative;
    background: ${({ theme }) => theme.colors.surface};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 8px;
    
    > .header {
      background: ${({ theme }) => theme.colors.surfaceHover};
      color: ${({ theme }) => theme.colors.textPrimary};
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
      padding: 20px 24px;
      font-size: 18px;
      font-weight: 500;
    }
    
    > .content {
      background: ${({ theme }) => theme.colors.surface};
      color: ${({ theme }) => theme.colors.textPrimary};
      padding: 24px;
      font-size: 15px;
      line-height: 1.5;
    }
    
    > .actions {
      background: ${({ theme }) => theme.colors.surfaceHover};
      border-top: 1px solid ${({ theme }) => theme.colors.border};
      padding: 16px 24px;
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }
`;

export const CancelButton = styled(Button)`
  &.ui.button {
    background: transparent;
    color: ${({ theme }) => theme.colors.textPrimary};
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 8px 16px;
    font-weight: 500;
    min-width: 80px;
    
    &:hover:not(:disabled) {
      background: ${({ theme }) => theme.colors.surfaceHover};
      border-color: ${({ theme }) => theme.colors.borderAccent};
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

export const DeleteButton = styled(Button)`
  &.ui.button {
    background: ${({ theme }) => theme.colors.accent};
    color: white;
    border: 1px solid transparent;
    padding: 8px 16px;
    font-weight: 500;
    min-width: 80px;
    
    .button-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    i.icon {
      margin: 0;
      font-size: 14px;
    }
    
    &:hover:not(:disabled) {
      border-color: ${({ theme }) => theme.colors.borderAccent};
      background: ${({ theme }) => theme.colors.accent};
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`; 