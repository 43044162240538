import React, { FC, useState } from 'react';
import { Checkbox, Input, Dropdown } from 'semantic-ui-react';
import { ElevenLabsVoiceSettings, VOICE_MODELS, VOICE_PROVIDERS } from '../../../../core/api/VoiceConfig';
import { SettingsContainer, SettingRow, Label, SliderContainer, ValueDisplay } from './styles';

export interface Props {
  settings: ElevenLabsVoiceSettings;
  onChange: (settings: ElevenLabsVoiceSettings) => void;
}

export const ElevenLabsSettings: FC<Props> = ({ settings, onChange }) => {
  console.log('ElevenLabsSettings render with settings:', settings);
  const [customModelId, setCustomModelId] = useState('');

  const handleChange = (key: keyof ElevenLabsVoiceSettings | keyof ElevenLabsVoiceSettings['options'], value: any) => {
    console.log('handleChange called with:', { key, value });
    if (key === 'model') {
      const newSettings = {
        ...settings,
        model: value
      };
      console.log('Updating model with settings:', newSettings);
      onChange(newSettings);
    } else {
      const newSettings = {
        ...settings,
        options: {
          ...settings.options,
          [key]: value
        }
      };
      console.log('Updating options with settings:', newSettings);
      onChange(newSettings);
    }
  };

  const handleModelChange = (value: string) => {
    console.log('handleModelChange called with:', value);
    if (value === 'custom') {
      handleChange('model', customModelId || '');
    } else {
      handleChange('model', value);
      setCustomModelId('');
    }
  };

  const handleCustomModelChange = (value: string) => {
    console.log('handleCustomModelChange called with:', value);
    setCustomModelId(value);
    handleChange('model', value);
  };

  const modelOptions = VOICE_MODELS[VOICE_PROVIDERS.ELEVEN_LABS].map(model => ({
    key: model.id,
    value: model.id,
    text: model.name
  }));

  const selectedModel = VOICE_MODELS[VOICE_PROVIDERS.ELEVEN_LABS].find(m => m.id === settings.model)
    ? settings.model
    : 'custom';
  
  console.log('Current state:', {
    selectedModel,
    customModelId,
    modelOptions,
    settings
  });

  return (
    <SettingsContainer>
      <SettingRow>
        <Label>Model</Label>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div style={{ flex: 1 }}>
            <Dropdown
              selection
              fluid
              options={modelOptions}
              value={selectedModel}
              onChange={(_, { value }) => handleModelChange(value as string)}
            />
          </div>
          {selectedModel === 'custom' && (
            <div style={{ flex: 2 }}>
              <Input 
                fluid
                placeholder="Enter custom model ID"
                value={customModelId || settings.model}
                onChange={(e) => handleCustomModelChange(e.target.value)}
              />
            </div>
          )}
        </div>
      </SettingRow>

      <SettingRow>
        <SliderContainer>
          <Label>Similarity Boost</Label>
          <Input 
            type="range"
            min={0}
            max={1}
            step={0.1}
            value={settings.options.similarityBoost}
            onChange={(e) => handleChange('similarityBoost', parseFloat(e.target.value))}
          />
          <ValueDisplay>{settings.options.similarityBoost.toFixed(1)}</ValueDisplay>
        </SliderContainer>
      </SettingRow>

      <SettingRow>
        <SliderContainer>
          <Label>Stability</Label>
          <Input
            type="range"
            min={0}
            max={1}
            step={0.1}
            value={settings.options.stability}
            onChange={(e) => handleChange('stability', parseFloat(e.target.value))}
          />
          <ValueDisplay>{settings.options.stability.toFixed(1)}</ValueDisplay>
        </SliderContainer>
      </SettingRow>

      <SettingRow>
        <SliderContainer>
          <Label>Style</Label>
          <Input
            type="range"
            min={0}
            max={1}
            step={0.1}
            value={settings.options.style}
            onChange={(e) => handleChange('style', parseFloat(e.target.value))}
          />
          <ValueDisplay>{settings.options.style.toFixed(1)}</ValueDisplay>
        </SliderContainer>
      </SettingRow>

      <SettingRow>
        <Checkbox
          label="Use Speaker Boost"
          checked={settings.options.useSpeakerBoost}
          onChange={(_, { checked }) => handleChange('useSpeakerBoost', checked)}
        />
      </SettingRow>

      <SettingRow>
        <Label>Streaming Latency</Label>
        <Input
          type="number"
          value={settings.options.optimizeStreamingLatency}
          onChange={(e) => handleChange('optimizeStreamingLatency', parseInt(e.target.value) || 0)}
          min={0}
        />
      </SettingRow>
    </SettingsContainer>
  );
}; 
