import React, { useState } from 'react';
import { Modal, Button, Message, Checkbox, Accordion, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useStore } from '../../../core/api/GlobalStoreContext';
import { Label, Input, TextArea } from './FormElements';
import { 
  FormSection, 
  StyledModal, 
  SmallText, 
  StyledDropdown,
} from './styles';
import { CloneRequest } from "@dasha.ai/sdk/src/rest-api/tts";
import type { TtsProvider } from "@dasha.ai/sdk/src/rest-api/generated/core/models";
import { AVAILABLE_LANGUAGES } from '../../../core/api/VoiceConfig';

const StyledAccordion = styled(Accordion)`
  &.ui.accordion {
    margin-bottom: 24px;
    
    .title {
      color: ${({ theme }) => theme.colors.textPrimary};
      background: transparent;
      border: none;
      padding: 8px 0;
      
      &:hover {
        color: ${({ theme }) => theme.colors.textPrimary};
      }
      
      .icon {
        color: ${({ theme }) => theme.colors.textPrimary};
      }
    }
    
    .content {
      padding: 0;
      background: transparent;
      border: none;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  &.ui.checkbox {
    margin-top: 8px;
    
    label {
      color: ${({ theme }) => theme.colors.textPrimary};
      
      &:before {
        background: ${({ theme }) => theme.colors.surfaceHover};
        border: 1px solid ${({ theme }) => theme.colors.border};
      }
      
      &:hover:before {
        background: ${({ theme }) => theme.colors.surfaceHover};
        border-color: ${({ theme }) => theme.colors.accent};
      }
    }
    
    &.checked label:before {
      background: ${({ theme }) => theme.colors.accent} !important;
      border-color: ${({ theme }) => theme.colors.accent} !important;
    }
    
    input:focus ~ label:before {
      background: ${({ theme }) => theme.colors.surfaceHover};
      border-color: ${({ theme }) => theme.colors.accent};
    }

    input:checked ~ label:after {
      color: #fff !important;
    }

    &.disabled {
      opacity: 0.5;
      
      label {
        color: ${({ theme }) => theme.colors.textSecondary} !important;
        cursor: not-allowed;
      }
      
      &:before, label:before {
        background: ${({ theme }) => theme.colors.surface} !important;
        border-color: ${({ theme }) => theme.colors.border} !important;
      }
    }
  }
`;

const SettingsGroup = styled.div`
  margin-top: 16px;
  
  ${Label} {
    margin-bottom: 8px;
    display: block;
  }
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.accent};
  text-decoration: underline;
  
  &:hover {
    color: ${({ theme }) => theme.colors.accentHover};
  }
`;

const PROVIDER_REQUIREMENTS = {
  elevenlabs: {
    maxFileSize: 10 * 1024 * 1024, // 10MB
    minDuration: 30, // seconds
    requirements: 'Maximum file size: 10MB, Minimum audio length: 30 seconds',
    consentText: (
      <>
        I hereby confirm that I have all necessary rights or consents to upload and clone these voice samples and that I will not use the platform-generated content for any illegal, fraudulent, or harmful purpose. I reaffirm my obligation to abide by ElevenLabs' <StyledLink href="https://elevenlabs.io/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Service</StyledLink>, <StyledLink href="https://elevenlabs.io/use-policy" target="_blank" rel="noopener noreferrer">Prohibited Content and Uses Policy</StyledLink> and <StyledLink href="https://elevenlabs.io/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</StyledLink>.
      </>
    )
  },
  cartesia: {
    stability: {
      duration: '15-20 seconds',
      enhance: true,
      requirements: 'Audio duration: 15-20 seconds, Enhance Audio: enabled'
    },
    similarity: {
      duration: '5 seconds',
      enhance: false,
      requirements: 'Audio duration: 5 seconds, Enhance Audio: disabled'
    },
    consentText: (
      <>
        By clicking Confirm, you certify that you have consent to clone these voice samples and that you will not use anything generated on Cartesia for illegal or harmful purposes. By proceeding, you agree to Cartesia's <StyledLink href="https://www.cartesia.ai/legal/terms.html" target="_blank" rel="noopener noreferrer">Terms of Service</StyledLink> and <StyledLink href="https://www.cartesia.ai/legal/acceptable-use.html" target="_blank" rel="noopener noreferrer">Acceptable Use Policy</StyledLink>.
      </>
    )
  }
} as const;

const DEFAULT_PROVIDER_SETTINGS: CloneRequest['providerSpecific'] = {
  elevenLabs: {
    removeBackgroundNoise: true
  },
  cartesia: {
    mode: "Similarity",
    enhance: false
  }
};

interface VoiceCloneModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (voiceId: string, language: string) => void;
}

export const VoiceCloneModal: React.FC<VoiceCloneModalProps> = observer(({ 
  isOpen, 
  onClose,
  onSuccess
}) => {
  const globalStore = useStore();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [newVoiceName, setNewVoiceName] = useState('');
  const [newVoiceDescription, setNewVoiceDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [selectedGender, setSelectedGender] = useState<string>('');
  const [selectedProvider, setSelectedProvider] = useState<TtsProvider | ''>('');
  const [isProviderSettingsOpen, setIsProviderSettingsOpen] = useState(false);
  const [hasConsent, setHasConsent] = useState(false);

  // Provider-specific settings with defaults
  const [providerSpecific, setProviderSpecific] = useState<CloneRequest['providerSpecific']>(DEFAULT_PROVIDER_SETTINGS);

  const resetForm = () => {
    setNewVoiceName('');
    setNewVoiceDescription('');
    setSelectedFile(null);
    setSelectedLanguage('');
    setSelectedGender('');
    setSelectedProvider('');
    setUploadError('');
    setProviderSpecific(DEFAULT_PROVIDER_SETTINGS);
    setIsProviderSettingsOpen(false);
    setHasConsent(false);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      setUploadError('Please select a file');
      return;
    }

    if (selectedProvider.toLowerCase() === 'elevenlabs' && file.size > PROVIDER_REQUIREMENTS.elevenlabs.maxFileSize) {
      setUploadError(`File size exceeds ${PROVIDER_REQUIREMENTS.elevenlabs.maxFileSize / (1024 * 1024)}MB limit.`);
      return;
    }

    // Create an audio element to check duration
    const audio = new Audio();
    const objectUrl = URL.createObjectURL(file);
    
    try {
      await new Promise((resolve, reject) => {
        audio.addEventListener('loadedmetadata', resolve);
        audio.addEventListener('error', reject);
        audio.src = objectUrl;
      });

      const duration = audio.duration;

      // Provider-specific validation
      if (selectedProvider.toLowerCase() === 'elevenlabs') {
        if (duration < PROVIDER_REQUIREMENTS.elevenlabs.minDuration) {
          setUploadError(`Audio must be at least ${PROVIDER_REQUIREMENTS.elevenlabs.minDuration} seconds long for ElevenLabs.`);
          return;
        }
      } else if (selectedProvider.toLowerCase() === 'cartesia') {
        const mode = providerSpecific.cartesia?.mode ?? 'Similarity';
        const modeSettings = PROVIDER_REQUIREMENTS.cartesia[mode.toLowerCase()];
        
        if (mode === 'Stability' && (duration < 15 || duration > 20)) {
          setUploadError('For Stability mode, audio must be between 15-20 seconds.');
          return;
        } else if (mode === 'Similarity' && Math.abs(duration - 5) > 0.5) {
          setUploadError('For Similarity mode, audio must be approximately 5 seconds.');
          return;
        }

        // Auto-set enhance based on mode
        setProviderSpecific(prev => ({
          ...prev,
          cartesia: {
            ...prev.cartesia,
            mode,
            enhance: modeSettings.enhance
          }
        }));
      }

      setSelectedFile(file);
      setUploadError('');
    } catch (error) {
      setUploadError('Error validating audio file. Please try another file.');
    } finally {
      URL.revokeObjectURL(objectUrl);
    }
  };

  const handleVoiceUpload = async () => {
    if (!selectedFile || !newVoiceName || !selectedLanguage || !selectedGender || !selectedProvider || !hasConsent) return;

    setIsUploading(true);
    setUploadError('');

    try {
      const cloneRequest: CloneRequest = {
        provider: selectedProvider,
        name: newVoiceName,
        description: newVoiceDescription || '',
        labels: {
          gender: selectedGender
        },
        providerSpecific,
        language: selectedLanguage,
      };

      const response = await globalStore.voiceStore.createVoice(cloneRequest, [selectedFile]);
      
      if (!response?.voiceId) {
        throw new Error('Failed to create voice: No voice ID returned');
      }

      onSuccess(response.voiceId, selectedLanguage);
      handleClose();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      const errorReason = 
        // Check direct reason property
        (error as any)?.reason ||
        // Check response data reason
        (error as any)?.response?.data?.reason ||
        // Check if the entire response.data is the error message
        (typeof (error as any)?.response?.data === 'string' ? (error as any).response.data : '') ||
        // Check other common error response formats
        (error as any)?.response?.data?.error ||
        '';

      setUploadError(
        errorReason ? `${errorMessage}: ${errorReason}` : errorMessage
      );
    } finally {
      setIsUploading(false);
    }
  };

  const renderProviderSpecificSettings = () => {
    switch (selectedProvider.toLowerCase()) {
      case 'elevenlabs':
        return (
          <StyledAccordion>
            <Accordion.Title
              active={isProviderSettingsOpen}
              onClick={() => setIsProviderSettingsOpen(!isProviderSettingsOpen)}
            >
              <Icon name="dropdown" />
              Advanced Settings (Optional)
            </Accordion.Title>
            <Accordion.Content active={isProviderSettingsOpen}>
              <FormSection>
                <StyledCheckbox
                  label="Remove Background Noise"
                  checked={providerSpecific.elevenLabs?.removeBackgroundNoise ?? true}
                  onChange={(_, { checked }) => {
                    setProviderSpecific(prev => ({
                      ...prev,
                      elevenLabs: {
                        ...prev.elevenLabs,
                        removeBackgroundNoise: checked || false
                      }
                    }));
                    setUploadError('');
                  }}
                />
              </FormSection>
            </Accordion.Content>
          </StyledAccordion>
        );

      case 'cartesia':
        const mode = providerSpecific.cartesia?.mode ?? 'Similarity';
        const modeSettings = PROVIDER_REQUIREMENTS.cartesia[mode.toLowerCase()];
        return (
          <StyledAccordion>
            <Accordion.Title
              active={isProviderSettingsOpen}
              onClick={() => setIsProviderSettingsOpen(!isProviderSettingsOpen)}
            >
              <Icon name="dropdown" />
              Advanced Settings (Optional)
            </Accordion.Title>
            <Accordion.Content active={isProviderSettingsOpen}>
              <FormSection>
                <SettingsGroup>
                  <Label>Mode</Label>
                  <StyledDropdown
                    selection
                    fluid
                    options={[
                      { key: 'stability', value: 'Stability', text: 'Stability' },
                      { key: 'similarity', value: 'Similarity', text: 'Similarity' }
                    ]}
                    value={mode}
                    onChange={(_, { value }) => {
                      const newModeSettings = PROVIDER_REQUIREMENTS.cartesia[value.toLowerCase()];
                      setProviderSpecific(prev => ({
                        ...prev,
                        cartesia: {
                          ...prev.cartesia,
                          mode: value as typeof mode,
                          enhance: newModeSettings.enhance
                        }
                      }));
                      setUploadError('');
                    }}
                  />
                </SettingsGroup>
                <StyledCheckbox
                  label="Enhance Audio"
                  checked={providerSpecific.cartesia?.enhance ?? modeSettings.enhance}
                  onChange={(_, { checked }) => {
                    setProviderSpecific(prev => ({
                      ...prev,
                      cartesia: {
                        ...prev.cartesia,
                        enhance: checked || false
                      }
                    }));
                    setUploadError('');
                  }}
                />
                <SettingsGroup>
                  <Label>Transcript (Optional)</Label>
                  <TextArea
                    value={providerSpecific.cartesia?.transcript || ''}
                    onChange={(e) => {
                      setProviderSpecific(prev => ({
                        ...prev,
                        cartesia: {
                          ...prev.cartesia,
                          transcript: e.target.value
                        }
                      }));
                      setUploadError('');
                    }}
                    placeholder="Enter the transcript of the audio file..."
                  />
                </SettingsGroup>
              </FormSection>
            </Accordion.Content>
          </StyledAccordion>
        );

      default:
        return null;
    }
  };

  const renderConsentSection = () => {
    if (!selectedProvider) return null;

    const consentText = selectedProvider.toLowerCase() === 'elevenlabs' 
      ? PROVIDER_REQUIREMENTS.elevenlabs.consentText
      : selectedProvider.toLowerCase() === 'cartesia'
        ? PROVIDER_REQUIREMENTS.cartesia.consentText
        : '';

    if (!consentText) return null;

    return (
      <FormSection>
        <StyledAccordion>
          <Accordion.Title active={true}>
            <Icon name="warning circle" />
            Terms & Conditions
          </Accordion.Title>
          <Accordion.Content active={true}>
            <div style={{ marginBottom: '16px' }}>
              <SmallText>{consentText}</SmallText>
            </div>
            <StyledCheckbox
              label="I agree to the terms and conditions"
              checked={hasConsent}
              onChange={(_, { checked }) => {
                setHasConsent(checked || false);
                setUploadError('');
              }}
            />
          </Accordion.Content>
        </StyledAccordion>
      </FormSection>
    );
  };

  const languageOptions = AVAILABLE_LANGUAGES.map(lang => ({
    key: lang.value,
    value: lang.value,
    text: lang.label,
    flag: lang.flag
  }));

  const genderOptions = [
    { key: 'female', value: 'female', text: 'Female' },
    { key: 'male', value: 'male', text: 'Male' }
  ];

  return (
    <StyledModal
      open={isOpen}
      onClose={handleClose}
      size="small"
    >
      <Modal.Header>Clone New Voice</Modal.Header>
      <Modal.Content>
        <FormSection>
          <Label>Provider *</Label>
          <StyledDropdown
            selection
            fluid
            error={!selectedProvider}
            options={globalStore.voiceStore?.getAvailableProviders().map(provider => ({
              key: provider,
              value: provider as TtsProvider,
              text: provider
            }))}
            value={selectedProvider}
            onChange={(_, { value }) => {
              setSelectedProvider(value as TtsProvider);
              setProviderSpecific({});
              setUploadError('');
            }}
            placeholder="Select voice provider..."
          />
        </FormSection>

        <FormSection>
          <Label>Voice Name *</Label>
          <Input
            type="text"
            value={newVoiceName}
            onChange={(e) => {
              setNewVoiceName(e.target.value);
              setUploadError('');
            }}
            placeholder="Enter a name for your cloned voice, for example: 'Mary' or 'John'"
          />
        </FormSection>

        <FormSection>
          <Label>Language *</Label>
          <StyledDropdown
            selection
            fluid
            options={languageOptions}
            value={selectedLanguage}
            onChange={(_, { value }) => {
              setSelectedLanguage(value as string);
              setUploadError('');
            }}
            placeholder="Select speaker's native language..."
            error={!selectedLanguage}
          />
        </FormSection>

        <FormSection>
          <Label>Gender *</Label>
          <StyledDropdown
            selection
            fluid
            options={genderOptions}
            value={selectedGender}
            onChange={(_, { value }) => {
              setSelectedGender(value as string);
              setUploadError('');
            }}
            placeholder="Select speaker's gender..."
            error={!selectedGender}
          />
        </FormSection>

        <FormSection>
          <Label>Description *</Label>
          <TextArea
            value={newVoiceDescription}
            onChange={(e) => {
              setNewVoiceDescription(e.target.value);
              setUploadError('');
            }}
            placeholder="Describe the voice, for example: 'Young 20-year-old female voice, friendly and cheerful, speaks with a soft and gentle accent.'"
          />
        </FormSection>

        {selectedProvider && renderProviderSpecificSettings()}

        <FormSection>
          <Label>Audio File *</Label>
          <Input
            type="file"
            accept="audio/*"
            onChange={handleFileSelect}
          />
          <SmallText>
            {selectedProvider && (
              <strong>Provider Requirements: </strong>
            )}
            {selectedProvider.toLowerCase() === 'elevenlabs' 
              ? PROVIDER_REQUIREMENTS.elevenlabs.requirements
              : selectedProvider.toLowerCase() === 'cartesia'
                ? PROVIDER_REQUIREMENTS.cartesia[
                    (providerSpecific.cartesia?.mode ?? 'Similarity').toLowerCase()
                  ].requirements
                : 'Please select a provider first'}
          </SmallText>
        </FormSection>

        {selectedProvider && renderConsentSection()}
      </Modal.Content>
      <Modal.Actions>
        {uploadError && (
          <Message negative style={{ marginBottom: '1rem', width: '100%' }}>
            <Message.Header>Error</Message.Header>
            <p>{uploadError}</p>
          </Message>
        )}
        <Button secondary onClick={handleClose}>
          Cancel
        </Button>
        <Button
          primary
          loading={isUploading}
          disabled={!selectedFile || !newVoiceName || !selectedLanguage || !selectedGender || !selectedProvider || !hasConsent || isUploading}
          onClick={handleVoiceUpload}
        >
          Clone Voice
        </Button>
      </Modal.Actions>
    </StyledModal>
  );
}); 