/**
 * This file defines the dark theme for the wizard
 * and centralizes all color references.
 */

import { DefaultTheme } from 'styled-components';

export const darkTheme: DefaultTheme = {
  colors: {
    // Core backgrounds
    background: '#161729',
    surface: '#1E1F34',
    surfaceHover: '#252642',
    primaryDark: '#161729',
    secondaryDark: '#1E1F28',
    
    // Borders
    border: '#2D3748',
    borderAccent: '#2D3748',
    borderDark: '#2D3748',
    
    // Interactive
    accent: '#6366F1',
    accentHover: '#5153c9',
    
    // Text
    textPrimary: '#FFFFFF',
    textSecondary: '#d9d9e2',
    textMuted: 'rgba(255, 255, 255, 0.6)',
    textDisabled: 'rgba(255, 255, 255, 0.4)',
    
    // Overlays
    overlayUltraLight: 'rgba(255, 255, 255, 0.02)',
    overlayLight: 'rgba(255, 255, 255, 0.1)',
    overlayMedium: 'rgba(255, 255, 255, 0.2)',
    
    // Status
    testSuccess: '#10B981',
    testFailure: '#EF4444',
    testSuccessBg: 'rgba(16, 185, 129, 0.1)',
    testFailureBg: 'rgba(239, 68, 68, 0.1)',
    codeBlockHeaderBg: 'rgba(26, 27, 35, 0.6)'
  }
}; 