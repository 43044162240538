import styled from 'styled-components';

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 4px;
`;

export const SettingRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ValueDisplay = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textSecondary};
  text-align: right;
`; 