import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon as SemanticIcon, Accordion } from 'semantic-ui-react';
import { useStore } from '../../core/api/GlobalStoreContext';
import { VoiceOptionDisplay } from '../WizardModal/components/VoiceOptionDisplay';
import { VoiceCloneModal } from '../WizardModal/components/VoiceCloneModal';
import { VoiceManagementPanel } from '../WizardModal/components/VoiceManagementPanel';
import { ElevenLabsSettings, CartesiaSettings, DashaSettings } from '../WizardModal/components/VoiceSettings';
import { 
  VOICE_PROVIDERS,
  type ProviderVoiceSettings,
  type ElevenLabsVoiceSettings,
  type CartesiaVoiceSettings,
  DashaVoiceSettings
} from '../../core/api/VoiceConfig';
import {
  Section,
  Label,
  StyledVoiceDropdown,
  ManageVoicesButton
} from './VoiceSettingsSection.styles';

// Create an observed version of VoiceOptionDisplay
const ObservedVoiceOptionDisplay = observer(VoiceOptionDisplay);

export interface VoiceSettingsSectionProps {
  voice: ProviderVoiceSettings;
  onChange: (value: ProviderVoiceSettings) => void;
  getVoiceOptions: () => any[];
  handleVoiceChange: (voiceId: string) => void;
}

// Type guards for voice settings
const isElevenLabsSettings = (settings: ProviderVoiceSettings): settings is ElevenLabsVoiceSettings => {
  return settings.provider === VOICE_PROVIDERS.ELEVEN_LABS;
};

const isCartesiaSettings = (settings: ProviderVoiceSettings): settings is CartesiaVoiceSettings => {
  return settings.provider === VOICE_PROVIDERS.CARTESIA;
};

const isDashaSettings = (settings: ProviderVoiceSettings): settings is DashaVoiceSettings => {
  return settings.provider === VOICE_PROVIDERS.DASHA;
}

export const VoiceSettingsSection: FC<VoiceSettingsSectionProps> = observer(({
  voice,
  onChange,
  getVoiceOptions,
  handleVoiceChange
}) => {
  const store = useStore();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [isVoiceSettingsOpen, setIsVoiceSettingsOpen] = useState(false);

  // Initialize VoiceStore on mount
  useEffect(() => {
    if (store.voiceStore && !store.voiceStore.loading && store.voiceStore.voices.length === 0) {
      store.voiceStore.TryReload();
    }
  }, [store.voiceStore]);

  // Get provider from selected voice
  const getSelectedVoiceProvider = () => {
    if (!voice?.providerVoiceId || !store.voiceStore) return null;
    const selectedVoice = store.voiceStore.voices.find(v => v.voiceId === voice?.providerVoiceId);
    return selectedVoice?.provider || null;
  };

  // Get voice options with error handling
  const getVoiceOptionsWithFallback = () => {
    try {
      return getVoiceOptions();
    } catch (error) {
      console.error('Error getting voice options:', error);
      return [];
    }
  };

  return (
    <Section>
      <Label>Voice</Label>
      <div style={{ display: 'flex', gap: '8px' }}>
        <StyledVoiceDropdown
          selection
          fluid
          loading={store.voiceStore?.loading}
          disabled={store.voiceStore?.loading}
          options={getVoiceOptionsWithFallback()}
          value={voice?.providerVoiceId || ''}
          onChange={(_, { value }) => {
            if (value === 'clone-option') {
              setIsUploadModalOpen(true);
            } else {
              handleVoiceChange(value as string);
            }
          }}
          placeholder="Select a voice..."
          search
          additionLabel=""
          trigger={voice?.providerVoiceId ? (() => {
            const voiceStore = store.voiceStore;
            if (!voiceStore) return null;
            const selectedVoice = voiceStore.voices.find(v => v.voiceId === voice?.providerVoiceId);
            if (!selectedVoice) return null;
            
            return (
              <ObservedVoiceOptionDisplay
                voice={selectedVoice}
                role="button"
              />
            );
          })() : undefined}
        />
        <ManageVoicesButton
          onClick={() => setIsManageModalOpen(true)}
          title="Manage Cloned Voices"
        >
          <SemanticIcon name="setting" />
        </ManageVoicesButton>
      </div>
      
      {voice?.providerVoiceId && (
        <Accordion style={{ marginTop: '16px' }}>
          <Accordion.Title
            active={isVoiceSettingsOpen}
            onClick={() => setIsVoiceSettingsOpen(!isVoiceSettingsOpen)}
          >
            <SemanticIcon name="dropdown" />
            Voice Settings
          </Accordion.Title>
          <Accordion.Content active={isVoiceSettingsOpen}>
            {(() => {
              const provider = getSelectedVoiceProvider();
              
              if (provider === VOICE_PROVIDERS.ELEVEN_LABS && isElevenLabsSettings(voice)) {
                return (
                  <ElevenLabsSettings
                    settings={voice}
                    onChange={newSettings => onChange({ ...voice, ...newSettings })}
                  />
                );
              }
              if (provider === VOICE_PROVIDERS.CARTESIA && isCartesiaSettings(voice)) {
                return (
                  <CartesiaSettings
                    settings={voice}
                    onChange={newSettings => onChange({ ...voice, ...newSettings })}
                  />
                );
              }
              if (provider === VOICE_PROVIDERS.DASHA && isDashaSettings(voice)) {
                return (
                  <DashaSettings
                    settings={voice}
                    onChange={newSettings => onChange({ ...voice, ...newSettings })}
                  />
                )
              }
              return null;
            })()}
          </Accordion.Content>
        </Accordion>
      )}

      <VoiceCloneModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        onSuccess={(voiceId, language) => {
          handleVoiceChange(voiceId);
          setIsUploadModalOpen(false);
        }}
      />

      <VoiceManagementPanel
        isOpen={isManageModalOpen}
        onClose={() => setIsManageModalOpen(false)}
        onDeleteVoice={() => {}}
      />
    </Section>
  );
}); 