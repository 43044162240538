import React, { useCallback, useRef } from 'react';
import type { FC } from 'react';
import * as dasha from "@dasha.ai/sdk/web";
import { VoiceOption } from './VoiceOptionDisplay.styles';

interface VoiceOptionDisplayProps {
  voice: dasha.coreapi.TtsCloneResponseDto;
  role?: string;
  compact?: boolean;
}

export const VoiceOptionDisplay: FC<VoiceOptionDisplayProps> = ({ voice, role, compact }) => {
  const name = voice.name || '';
  const gender = voice.labels?.gender || '';
  const language = voice.language || '';
  const description = voice.description || '';
  const provider = voice.provider || '';
  const voiceId = voice.voiceId || '';
  const idBadgeRef = useRef<HTMLSpanElement>(null);
  
  const ariaLabel = `${name}, ${gender} voice, ${language} language${description ? `, ${description}` : ''}, provided by ${provider}, ID: ${voiceId}`;

  const handleCopyId = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(voiceId).then(() => {
      if (idBadgeRef.current) {
        idBadgeRef.current.classList.add('copy-success');
        setTimeout(() => {
          if (idBadgeRef.current) {
            idBadgeRef.current.classList.remove('copy-success');
          }
        }, 300);
      }
    });
  }, [voiceId]);

  return (
    <VoiceOption role={role}>
      <div className="name-row">
        <div className="name-section">
          <span className="name">{name}</span>
          {description && (
            <span className="description">{description}</span>
          )}
        </div>
        <div className="info">
          <span className="badge language-badge">
            {language}
          </span>
          <span className="badge gender-badge">
            {gender}
          </span>
          {!compact && provider && (
            <span className="badge provider-badge">
              {provider.toString()}
            </span>
          )}
          {voiceId && (
            <span 
              ref={idBadgeRef}
              className="badge id-badge" 
              onClick={handleCopyId}
              role="button"
              tabIndex={0}
              title="Click to copy voice ID"
              aria-label={`Copy voice ID: ${voiceId}`}
            >
              {voiceId}
              <span className="copy-icon">⎘</span>
            </span>
          )}
          {voice.labels && Object.entries(voice.labels)
            .filter(([k]) => k !== "gender")
            .map(([key, value]) => (
              <span key={key} className="badge label">
                {value}
              </span>
            ))}
        </div>
      </div>
    </VoiceOption>
  );
}; 