import React, { type FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../core/api/GlobalStoreContext';
import { Icon } from 'semantic-ui-react';
import { VoiceOptionDisplay } from './VoiceOptionDisplay';
import { toJS } from 'mobx';
import { ConfirmationDialog } from './ConfirmationDialog';
import {
  SlidePanel,
  Header,
  Title,
  CloseButton,
  Content,
  VoiceItem,
  DeleteButton,
  EmptyState,
  Overlay
} from './VoiceManagementPanel.styles';
import { DEFAULT_PROVIDER_SETTINGS, VOICE_PROVIDERS } from '../../../core/api/VoiceConfig';

interface VoiceManagementPanelProps {
  isOpen: boolean;
  onClose: () => void;
  onDeleteVoice: (voiceId: string) => void;
}

export const VoiceManagementPanel: FC<VoiceManagementPanelProps> = observer(({ isOpen, onClose, onDeleteVoice }) => {
  const globalStore = useStore();
  const [deleteVoiceId, setDeleteVoiceId] = React.useState<string | null>(null);
  const [isDeleting, setIsDeleting] = React.useState(false);

  // Get store reference
  const voiceStore = globalStore.voiceStore;
  if (!voiceStore) return null;

  // Use computed property directly from store
  const clonedVoices = voiceStore.voices.filter(voice => voice.category === 'Cloned');
  const voiceToDelete = clonedVoices.find(voice => voice.voiceId === deleteVoiceId);

  // Add effect to reload voices when panel opens
  React.useEffect(() => {
    if (isOpen && voiceStore) {
      console.log('[Debug] VoiceManagementPanel opened, calling TryReload');
      console.log('[Debug] Voices before TryReload:', toJS(voiceStore.voices));
      voiceStore.TryReload();
      console.log('[Debug] Voices after TryReload:', toJS(voiceStore.voices));
    }
  }, [isOpen, voiceStore]);

  // Update delete handler to refresh wizard store
  const handleDelete = async (voiceId: string) => {
    try {
      setIsDeleting(true);
      await voiceStore.deleteVoice(voiceId);
      await onDeleteVoice(voiceId);
    } catch (error) {
      console.error('Failed to delete voice:', error);
    } finally {
      setIsDeleting(false);
      setDeleteVoiceId(null);
    }
  };

  return (
    <>
      <Overlay isOpen={isOpen} onClick={onClose} />
      <SlidePanel isOpen={isOpen}>
        <Header>
          <Title>Manage Cloned Voices</Title>
          <CloseButton icon basic onClick={onClose}>
            <Icon name="close" />
          </CloseButton>
        </Header>
        <Content>
          {!clonedVoices.length ? (
            <EmptyState>
              <Icon name="microphone slash" size="large" />
              <div>No cloned voices available</div>
            </EmptyState>
          ) : (
            clonedVoices.map(voice => (
              <VoiceItem 
                key={voice.voiceId}
                role="article"
                aria-label={`Voice: ${voice.name}`}
              >
                <VoiceOptionDisplay
                  voice={voice}
                  role="button"
                />
                <DeleteButton
                  icon
                  basic
                  disabled={isDeleting}
                  onClick={() => setDeleteVoiceId(voice.voiceId)}
                  title="Delete voice"
                  aria-label={`Delete ${voice.name}`}
                >
                  <Icon name="trash alternate outline" />
                </DeleteButton>
              </VoiceItem>
            ))
          )}
        </Content>
      </SlidePanel>

      <ConfirmationDialog
        isOpen={!!deleteVoiceId}
        onCancel={() => setDeleteVoiceId(null)}
        onConfirm={() => deleteVoiceId && handleDelete(deleteVoiceId)}
        title="Delete Voice"
        content={`Are you sure you want to delete "${voiceToDelete?.name || ''}"? This action cannot be undone.`}
        isLoading={isDeleting}
      />
    </>
  );
}); 