import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const SlidePanel = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-600px'};
  width: 600px;
  height: 100vh;
  background: ${({ theme }) => theme.colors.primaryDark};
  box-shadow: -2px 0 12px rgba(0, 0, 0, 0.25);
  transition: right 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${({ theme }) => theme.colors.borderDark};
`;

export const Header = styled.div`
  padding: 20px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderDark};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.primaryDark};
`;

export const Title = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 18px;
  font-weight: 500;
`;

export const CloseButton = styled(Button)`
  &.ui.button {
    padding: 6px !important;
    width: 28px;
    height: 28px;
    margin: 0 !important;
    background: transparent !important;
    color: ${({ theme }) => theme.colors.textSecondary} !important;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
      color: ${({ theme }) => theme.colors.textPrimary} !important;
      border-color: ${({ theme }) => theme.colors.borderDark};
      background: ${({ theme }) => theme.colors.secondaryDark} !important;
    }

    i.icon {
      margin: 0 !important;
      font-size: 16px;
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background: ${({ theme }) => theme.colors.primaryDark};

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.primaryDark};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.borderDark};
    border-radius: 4px;
    
    &:hover {
      background: ${({ theme }) => theme.colors.secondaryDark};
    }
  }
`;

export const VoiceItem = styled.div`
  position: relative;
  margin-bottom: 4px;
  padding: 6px 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderDark};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.surface};
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.borderAccent};
    background: ${({ theme }) => theme.colors.surfaceHover};
  }
`;

export const DeleteButton = styled(Button)`
  &.ui.button {
    padding: 4px !important;
    width: 22px;
    height: 22px;
    margin: 0 !important;
    margin-left: 8px !important;
    background: transparent !important;
    color: ${({ theme }) => theme.colors.textSecondary} !important;
    opacity: 0.7;
    transition: all 0.2s ease;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
      opacity: 1;
      color: ${({ theme }) => theme.colors.accent} !important;
      border-color: ${({ theme }) => theme.colors.borderDark};
      background: ${({ theme }) => theme.colors.surfaceHover} !important;
    }

    &:disabled {
      opacity: 0.3 !important;
      cursor: not-allowed !important;
    }

    i.icon {
      margin: 0 !important;
      font-size: 11px;
    }
  }
`;

export const EmptyState = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.textSecondary};
  padding: 40px 16px;
  font-size: 14px;

  i.icon {
    color: ${({ theme }) => theme.colors.textSecondary};
    opacity: 0.5;
    margin-bottom: 16px !important;
  }
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${props => props.isOpen ? 1 : 0};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
`; 