import styled from 'styled-components';
import { Input as SemanticInput, TextArea as SemanticTextArea, Dropdown as SemanticDropdown } from 'semantic-ui-react';

export const Input = styled(SemanticInput)`
  && {
    background: ${({ theme }) => theme.colors.background} !important;
    border: 1px solid ${({ theme }) => theme.colors.border} !important;
    border-radius: 8px !important;
    color: ${({ theme }) => theme.colors.textPrimary} !important;
    font-size: 14px !important;
    padding: 12px !important;
    transition: all 0.2s ease !important;
    width: 100% !important;
    box-shadow: none !important;
    height: auto !important;
    min-height: unset !important;
    line-height: normal !important;

    & > input {
      background: transparent !important;
      border: none !important;
      padding: 0 !important;
      height: auto !important;
      line-height: inherit !important;
      color: inherit !important;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.textDisabled} !important;
      padding: 0 !important;
    }

    &:hover:not(:disabled) {
      background: ${({ theme }) => theme.colors.surfaceHover} !important;
      border-color: ${({ theme }) => theme.colors.accent} !important;
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.accent} !important;
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.accent} !important;
      outline: none !important;
    }
  }
`;

export const TextArea = styled(SemanticTextArea)`
  && {
    background: ${({ theme }) => theme.colors.background} !important;
    border: 1px solid ${({ theme }) => theme.colors.border} !important;
    border-radius: 8px !important;
    color: ${({ theme }) => theme.colors.textPrimary} !important;
    font-size: 14px !important;
    padding: 12px !important;
    min-height: 120px;
    transition: all 0.2s ease !important;
    width: 100% !important;
    resize: none !important;
    box-shadow: none !important;

    &::placeholder {
      color: ${({ theme }) => theme.colors.textDisabled} !important;
      padding: 0 !important;
    }

    &:hover:not(:disabled) {
      background: ${({ theme }) => theme.colors.surfaceHover} !important;
      border-color: ${({ theme }) => theme.colors.accent} !important;
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.accent} !important;
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.accent} !important;
      outline: none !important;
    }
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Dropdown = styled(SemanticDropdown)`
  && {
    &.ui.selection.dropdown {
      background: ${({ theme }) => theme.colors.background} !important;
      border: 1px solid ${({ theme }) => theme.colors.border} !important;
      border-radius: 8px !important;
      color: ${({ theme }) => theme.colors.textPrimary} !important;
      padding: 10px 12px !important;
      min-height: unset !important;
      font-size: 14px !important;

      // Menu styling
      .menu {
        background: #161729 !important;
        border: none !important;
        margin-top: 4px !important;
        overflow: hidden !important;

        // Menu items
        > .item {
          border: none !important;
          color: ${({ theme }) => theme.colors.textSecondary} !important;
          padding: 8px 12px !important;
          font-size: 14px !important;
          background: transparent !important;
          transition: all 0.15s ease !important;

          &:hover {
            background: ${({ theme }) => theme.colors.surfaceHover} !important;
            color: ${({ theme }) => theme.colors.textPrimary} !important;
          }

          &.selected {
            background: rgba(99, 102, 241, 0.2) !important;
            color: ${({ theme }) => theme.colors.accent} !important;
          }

          // Remove all divider-related styles
          &:after, &:before {
            display: none !important;
          }
        }

        // Remove any divider elements
        .divider, .divider.item {
          height: 0 !important;
          margin: 0 !important;
          padding: 0 !important;
          border: none !important;
          display: none !important;
        }
      }
    }
  }
`;

// Add other form elements as needed 