import React from 'react';
import type { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import { StyledConfirmModal, CancelButton, DeleteButton, LoadingOverlay } from './ConfirmationDialog.styles';

interface ConfirmationDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
  content: string;
  isLoading?: boolean;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  title = 'Confirm Action',
  content,
  isLoading = false
}) => {
  return (
    <StyledConfirmModal
      open={isOpen}
      onClose={onCancel}
      size="tiny"
    >
      <LoadingOverlay visible={isLoading} />
      <StyledConfirmModal.Header>
        {title}
      </StyledConfirmModal.Header>
      <StyledConfirmModal.Content>
        {content}
      </StyledConfirmModal.Content>
      <StyledConfirmModal.Actions>
        <CancelButton onClick={onCancel} disabled={isLoading}>
          Cancel
        </CancelButton>
        <DeleteButton onClick={onConfirm} disabled={isLoading}>
          <span className="button-content">
            {isLoading && <Icon loading name="spinner" />}
            <span>{isLoading ? 'Deleting...' : 'Delete'}</span>
          </span>
        </DeleteButton>
      </StyledConfirmModal.Actions>
    </StyledConfirmModal>
  );
}; 