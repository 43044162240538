import styled from 'styled-components';

export const VoiceOption = styled.div`
  display: flex;
  width: 100%;
  padding: 2px 0;
  
  .name-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 8px;
  }
  
  .name-section {
    display: flex;
    align-items: baseline;
    gap: 8px;
  }
  
  .name {
    font-weight: 500;
    color: inherit;
    text-align: left;
  }

  .description {
    font-size: 11px;
    color: ${({ theme }) => theme.colors.textSecondary};
    opacity: 0.8;
  }
  
  .info {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: nowrap;
  }
  
  .badge {
    padding: 1px 6px;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
  }

  .language-badge {
    background: ${({ theme }) => theme.colors.accent};
    color: white;
  }
  
  .gender-badge, .label {
    background: ${({ theme }) => theme.colors.surface}CC;
    color: ${({ theme }) => theme.colors.textPrimary};
    white-space: nowrap;
  }

  .provider-badge {
    background: ${({ theme }) => theme.colors.surface}99;
    color: ${({ theme }) => theme.colors.textSecondary};
    font-style: italic;
  }
  
  .id-badge {
    background: ${({ theme }) => theme.colors.surface}EE;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: background-color 0.2s;
    
    &:hover {
      background: ${({ theme }) => theme.colors.surface};
    }
    
    &:active {
      transform: scale(0.98);
    }
    
    .copy-icon {
      opacity: 0.6;
      font-size: 10px;
    }
  }
  
  @keyframes copySuccess {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.1); }
  }
  
  .copy-success {
    animation: copySuccess 0.3s ease;
  }
`; 