import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import {
  createVoiceSettings,
  findVoiceById,
  getSearchableVoiceContent,
  ProviderVoiceSettings,
} from "../../core/api/VoiceConfig";
import { VoiceSettingsSection } from "./VoiceSettingsSection";
import { useStore } from "../../core/api/GlobalStoreContext";
import { Icon as SemanticIcon, Accordion } from "semantic-ui-react";
import { VoiceOptionDisplay } from "../WizardModal/components/VoiceOptionDisplay";

export interface VoiceSelectorProps {
  voice: ProviderVoiceSettings;
  handleChange: (newVoice: ProviderVoiceSettings) => void;
}
export const VoiceSelector: FC<VoiceSelectorProps> = observer(({ voice, handleChange }) => {
  const globalStore = useStore();
  return (
    <VoiceSettingsSection
      voice={voice}
      onChange={(value) => handleChange(value)}
      getVoiceOptions={() => {
        const voiceStore = globalStore.voiceStore;
        if (!voiceStore) return [];

        const allVoices = voiceStore.voices;
        const providers = voiceStore.getAvailableProviders();

        // Create base options with Clone New Voice
        const baseOptions = [
          {
            key: "clone-option",
            value: "clone-option",
            text: "Clone New Voice",
            content: (
              <div>
                <SemanticIcon name="copy" />
                Clone New Voice
              </div>
            ),
          },
        ];

        // Group voices by provider
        const voiceOptions = providers.flatMap((provider) => {
          const providerVoices = allVoices.filter((voice) => voice.provider === provider);
          if (providerVoices.length === 0) return [];

          return [
            {
              key: `header-${provider}`,
              value: `header-${provider}`,
              text: provider,
              disabled: true,
              className: "dropdown-header",
            },
            ...providerVoices.map((voice) => ({
              key: voice.voiceId,
              value: voice.voiceId,
              text: getSearchableVoiceContent(voice),
              content: <VoiceOptionDisplay voice={voice} role="option" />,
            })),
          ];
        });

        // Update selected voice if needed
        if (voice?.providerVoiceId) {
          const selectedVoice = findVoiceById(allVoices, voice.providerVoiceId);
          if (selectedVoice) {
            const selectedIndex = voiceOptions.findIndex((opt) => opt.value === voice?.providerVoiceId);
            if (selectedIndex > -1) {
              voiceOptions[selectedIndex] = {
                ...voiceOptions[selectedIndex],
                text: getSearchableVoiceContent(selectedVoice),
                content: <VoiceOptionDisplay voice={selectedVoice} role="option" />,
              };
            }
          }
        }

        return [...baseOptions, ...voiceOptions];
      }}
      handleVoiceChange={(voiceId) => {
        const newVoice = findVoiceById(globalStore.voiceStore?.voices || [], voiceId);
        if (!newVoice) return;

        // Use createVoiceSettings to properly initialize all voice settings
        const newSettings = createVoiceSettings(newVoice, voice);
        handleChange(newSettings);
      }}
    />
  );
});
