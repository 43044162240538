import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';

export const Section = styled.div`
  margin-bottom: 1.5rem;
`;

export const Label = styled.label`
  display: block;
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 0.5rem;
`;

export const StyledVoiceDropdown = styled(Dropdown)`
  &.ui.search.selection.dropdown {
    background: ${({ theme }) => theme.colors.surfaceHover};
    border: 1px solid ${({ theme }) => theme.colors.border};
    color: ${({ theme }) => theme.colors.textPrimary};
    min-height: 38px !important;
    padding: 8px 12px !important;
    padding-right: 32px !important;
    
    > input.search {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      padding: inherit !important;
      padding-right: 32px !important;
      color: ${({ theme }) => theme.colors.textPrimary} !important;
      background: transparent !important;
    }
    
    &.active.search {
      > .text, > [role="button"] {
        display: none !important;
      }
    }
    
    > .text {
      color: ${({ theme }) => theme.colors.textPrimary} !important;
    }
    
    > .dropdown.icon {
      color: ${({ theme }) => theme.colors.textSecondary} !important;
    }
    
    .menu {
      background: ${({ theme }) => theme.colors.surfaceHover} !important;
      border: 1px solid ${({ theme }) => theme.colors.border} !important;
      
      .item {
        border-top: none !important;
        color: ${({ theme }) => theme.colors.textPrimary} !important;
        
        &:hover {
          background: ${({ theme }) => theme.colors.surface} !important;
        }
        
        &.selected {
          background: ${({ theme }) => theme.colors.accent}20 !important;
        }
      }
    }
  }
`;

export const ManageVoicesButton = styled.button`
  padding: 0.5rem;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.textSecondary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.surface};
    color: ${({ theme }) => theme.colors.textPrimary};
    border-color: ${({ theme }) => theme.colors.accent};
  }
`; 