import React, { FC, useState } from 'react';
import { Input, Dropdown } from 'semantic-ui-react';
import { VOICE_MODELS, VOICE_PROVIDERS, DashaVoiceSettings } from '../../../../core/api/VoiceConfig';
import { SettingsContainer, SettingRow, Label, SliderContainer, ValueDisplay } from './styles';

export interface Props {
  settings: DashaVoiceSettings;
  onChange: (settings: DashaVoiceSettings) => void;
}

export const DashaSettings: FC<Props> = ({ settings, onChange }) => {
  const handleChange = (key: keyof DashaVoiceSettings, value: any) => {
    onChange({
      ...settings,
      [key]: value
    });
  };

  return (
    <SettingsContainer>
      <SettingRow>
        <SliderContainer>
          <Label>Speed</Label>
          <Input
            type="range"
            min={0.5}
            max={2}
            step={0.1}
            value={settings.speed}
            onChange={(e) => handleChange('speed', parseFloat(e.target.value))}
          />
          <ValueDisplay>{settings.speed.toFixed(1)}</ValueDisplay>
        </SliderContainer>
      </SettingRow>
    </SettingsContainer>
  );
}; 