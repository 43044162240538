import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import Modal from '../uikit/Modal';
import { buildInfo } from '../core/config/version';
import { ReactComponent as DashaLogo } from '../HeaderPanel/logo.svg';
import { ActionButton } from '../uikit';

const Content = styled.div`
  padding: 32px;
  color: #d9dae5;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  background: #1d1e31;
  border-radius: 8px;
`;

const LogoContainer = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    width: 200px;
    height: 48px;
    
    path {
      fill: #ffffff;
    }
  }
`;

const SystemInfo = styled.pre`
  background: #161729;
  border-radius: 8px;
  padding: 16px;
  margin: 0;
  font-family: Menlo, Monaco, "Courier New", monospace;
  font-size: 12px;
  line-height: 1.6;
  color: #d9dae5;
  text-align: left;
  position: relative;
  overflow: auto;
  max-height: 400px;
`;

const CopyButton = styled(ActionButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  min-width: unset;
  height: unset;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #d9dae5;
  padding: 6px 12px;
  font-size: 12px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 500;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
    color: #ffffff;
  }
`;

interface AboutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AboutModal: FC<AboutModalProps> = ({ isOpen, onClose }) => {
  const timeSinceBuild = Math.floor((Date.now() - new Date(buildInfo.buildDate).getTime()) / (1000 * 60 * 60 * 24));
  
  // Collect system information
  const systemInfo = {
    'Studio Version': buildInfo.version,
    'Environment': buildInfo.environment,
    'Build Info': {
      'Branch': buildInfo.branch,
      'Commit': buildInfo.commitHash,
      'Date': `${buildInfo.buildDate} (${timeSinceBuild} days ago)`,
    },
    'System Info': {
      'Chrome Version': getBrowserInfo(),
      'OS': getOSInfo(),
      'Language': navigator.language,
      'Screen Resolution': `${window.screen.width}x${window.screen.height}`,
      'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
  };

  const formatInfo = (obj: any, level = 0): string => {
    return Object.entries(obj)
      .map(([key, value]) => {
        const indent = '  '.repeat(level);
        if (typeof value === 'object' && value !== null) {
          return `${indent}${key}:\n${formatInfo(value, level + 1)}`;
        }
        return `${indent}${key}: ${value}`;
      })
      .join('\n');
  };

  const infoText = formatInfo(systemInfo);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(infoText).then(() => {
      // Could add a toast notification here if we have one
      console.log('System info copied to clipboard');
    });
  }, [infoText]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="">
      <Content>
        <LogoContainer>
          <DashaLogo />
        </LogoContainer>
        <SystemInfo>
          {infoText}
          <CopyButton onClick={handleCopy}>
            Copy
          </CopyButton>
        </SystemInfo>
      </Content>
    </Modal>
  );
};

// Helper function to get browser information
function getBrowserInfo() {
  const ua = navigator.userAgent;
  const chromeMatch = ua.match(/Chrome\/([\d.]+).*?\((.*?)\)/);
  if (chromeMatch) {
    const [, version, buildInfo] = chromeMatch;
    return `${version} (${buildInfo})`;
  }
  return ua;
}

// Helper function to get OS information
function getOSInfo() {
  const ua = navigator.userAgent;
  const osMatch = ua.match(/\((.*?)\)/);
  if (osMatch) {
    const osInfo = osMatch[1];
    // Extract Darwin version and architecture
    const darwinMatch = osInfo.match(/Darwin ([\d.]+).*?(arm64|x86_64)?/i);
    if (darwinMatch) {
      const [, version, arch] = darwinMatch;
      return `Darwin ${arch || ''} ${version}`.trim();
    }
    return osInfo;
  }
  return navigator.platform;
} 